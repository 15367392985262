
import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Container from '@material-ui/core/Container';
import FormHelperText from '@material-ui/core/FormHelperText'
import { useField } from 'formik'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

const FormInput = (props) => {
  const [field, meta] = useField(props);
  const hasError = Boolean(meta.touched && meta.error)

  const radios = props.options.map((v, i) => {

    return (
      <FormControlLabel
        key={i}
        value={v.value}
        control={<Radio color="primary" />}
        label={v.label}
        labelPlacement="end"
      />
    );
  });

  return (
    <Grid key={props.legend} item xs={12} style={{marginTop: 10}}>
    <Container align="left">
        <FormControl error={hasError} component="fieldset" size="small">
          <FormLabel component="legend">{props.legend}</FormLabel>
          <RadioGroup aria-label="position" {...field} row>
          {radios}
          </RadioGroup>
          {hasError && <FormHelperText>Please complete this question</FormHelperText>}
       </FormControl>
      </Container>
      <Divider/>
    </Grid>
  );
};

export default FormInput;