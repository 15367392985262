/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:413b2f50-9e10-4d8e-94d4-704b910ddeb1",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_RJihY8BZe",
    "aws_user_pools_web_client_id": "2npgssn5vmkk2umesu1eklv65j",
    "oauth": {},
    "aws_user_files_s3_bucket": "cernova-forms-configprod-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-2",
    "aws_dynamodb_all_tables_region": "ap-southeast-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "formstorage-prod",
            "region": "ap-southeast-2"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "formapi",
            "endpoint": "https://l1f88h56i6.execute-api.ap-southeast-2.amazonaws.com/prod",
            "region": "ap-southeast-2"
        }
    ],
    "aws_content_delivery_bucket": "cernova-forms-dev-prod",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://d3353q77sn1bl2.cloudfront.net"
};


export default awsmobile;
