import React, { useState } from 'react';
import {API} from 'aws-amplify';
import Container from '@material-ui/core/Container';
import Radio from './FormInputRadio'
import Select from './FormInputSelect'
import { useFormik } from 'formik'
import FormikForm from './FormikForm'
import * as Yup from "yup"
import validator from '../assets/validator'
import FormButton from './FormButton'
import Grid from '@material-ui/core/Grid';
import TextInput from './FormInputText';
import Calendar from './FormInputCalendar';
import ColorPicker from './FormInputColorPicker'
import {subYears, formatISO} from "date-fns";
import Paper from '@material-ui/core/Paper';
import Success from '../pages/Success'
const AssessmentForm = (props) => {
  const [sucessfullySubmitted, setSucessfullySubmitted] = useState({submitted: false, error:false});
  const [noConnection, setNoConnection] = useState(false);

  const formComponents = props.config.form.formComponents 
  
  const Questions = formComponents.map((data) => {
    
   // let formComponentIds = formComponentIds[data.paramId] = ''
    switch(data.type){
      case 'radio':
       //console.log(data)
        return(  
          <Radio 
          options={data.options} 
          key={data.paramId}
          legend={data.question}
          name={data.paramId}
          />
        )
      case 'dropdown':
        return(
         <Select 
           options={data.options}
           key={data.paramId}
           legend={data.question}
           name={data.paramId}
         />
        )

      case 'text':
        return(
          <TextInput
          key={data.paramId}
          id={data.paramId}
          name={data.paramId}
          label={data.label}
          width={6}
          />)
  
      case 'date':
          return(
          <Calendar
          key={data.paramId}
          id={data.paramId}
          name={data.paramId}
          />)

      case 'colorPicker':
        return(
          <ColorPicker 
          key={data.paramId}
          show={true}
          name={data.paramId}
          extraColors={data.extraColors ? true : false}
          windowSmall={props.windowSmall}
          />)

      default: 
        return null
    }
  });

  function setInitalValues(){
    const d =formComponents 
    var x = {}

    d.forEach(component =>{  
      var i ={}
      if(component['type'] === 'colorPicker'){
        i[component['paramId']]=[]
      } else if(component['type'] === 'staticValue'){
        i[component['paramId']]=component['value']
      }else i[component['paramId']]=''
      x = {...x,...i}
    })
    return x  
  }

  function ValidationSchema () {
    const d =formComponents
    var x = {}

    d.forEach(component =>{  

      var i ={}
      if (component['type'] === "text"){
      switch (component.textType){
        
        case 'name':
          i[component['paramId']]=Yup.string().matches(validator.name).min(1, "Too Short!").required()
            break

        case 'email':
          i[component['paramId']]=Yup.string().email().required()
            break
            
        default: 
          break
        } 
      } else if(component['type'] === "date"){
        i[component['paramId']]=Yup.date().min(formatISO(subYears(new Date(), 100), { representation: 'date' })).max(formatISO(subYears(new Date(), 10), { representation: 'date' })).required()
      } else if(component['type'] === "colorPicker"){

        const standardColors = ["white","black","darkgreen","gray","green","darkblue","lightgreen","lightblue","yellow","pink","brown","purple", "orange","red"]
        const extraColors = ["strongRed", "lightCyan"]

        if (component['extraColors'] === true){
        i[component['paramId']]=Yup.array().min(16).of(Yup.mixed().oneOf([...standardColors, ...extraColors])).required()
        } else{
        i[component['paramId']]=Yup.array().min(14).of(Yup.mixed().oneOf(standardColors)).required()
        }
      }
      else{
      i[component['paramId']]=Yup.string().min(1, "Too Short!").required()
       }
        x = {...x,...i}
    })
    return Yup.object().shape(x)
}

  const formik = useFormik({
    initialValues:setInitalValues() ,
    validationSchema:ValidationSchema(),

    onSubmit: values => {

      const myInit = {
        body: { values: { ...values, fname: values.fname.trim(), lastname: values.lastname.trim()}}, // replace this with attributes you need
        headers: {}, // OPTIONAL
      }
      const apiName = 'formapi';
      const path = '/form/submission';
    
      console.log("posting data . . . . .")
      API.post(apiName, path, myInit).then(response => {
          console.log("Submission sucessful")
          formik.setSubmitting(false)
          setSucessfullySubmitted({submitted:true, error:false})
        })     
        .catch(err =>{
          formik.setSubmitting(false)
          if(err.response){ 
            setNoConnection(false)
            if (err.response.status === "400"){
            setSucessfullySubmitted({submitted:true, error:true})
            console.log("invalid form data")}
        }else setNoConnection(true)
        });        

    },
  });

  return (

    <Container key={"form20"} maxWidth="md">
     <FormikForm formik={formik}>
     {!sucessfullySubmitted.submitted ?
        <Grid container spacing={3}>
          {Questions}
          <FormButton width={6} text={"submit"} loading={formik.isSubmitting} />
          {noConnection&&<Paper variant="outlined" elevation={3}>Connection can not be established please try again</Paper>}
        </Grid>
       :<Success error={sucessfullySubmitted.error}/>
     }
      </FormikForm>
    </Container>

  );
}

export default AssessmentForm;