import React, {useEffect, useRef} from 'react'
import { Button, CircularProgress } from '@material-ui/core/';
import {useFormikContext} from 'formik'
import Grid from '@material-ui/core/Grid';


export default function FormButton(props) {
    const { callback, loading, text, dialog, width } = props
    const formik = useFormikContext()
    const buttonProps = callback ? { onClick:callback } : { 
        type:'submit' 
    }
    const buttonUIProps = 
        dialog === true ?
            {
                color:"primary" 
            }
        :
            {
                variant:"contained" ,
                fullWidth:true,
                color:"primary",
                size: "large"
            }

            const submitCountRef = useRef()
            useEffect(()=>{
                if (submitCountRef.current !== formik.submitCount && !formik.isSubmitting) {
                  submitCountRef.current = formik.submitCount
                  if (formik.errors) {
                    window.scrollTo(0, 0)
                  }
                }
            }, [formik.submitCount, formik.isSubmitting, formik.errors])
    return (
            <Grid item xs={12} sm={width}>
                <Button {...buttonProps} {...buttonUIProps} disabled={formik.isSubmitting || loading}>
                  {formik.isSubmitting || loading ? <CircularProgress size={24}/> : text }
                </Button>
            </Grid>
 
    )
}
