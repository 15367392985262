import React from 'react';
import Typography from '@material-ui/core/Typography';

const Success = (props) => {

    return (
        <div>
            <Typography variant="h3">{!props.error ? "Congratulations!" : "Sorry an error occured"}</Typography>
            <Typography variant="h6">{!props.error ? "You have successfully submitted!" : "Form submission invalid, Please try again" }</Typography> 
        </div>
    )
}
//TODO Add display on submission and tick icon.
export default Success;