import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import FormHelperText from '@material-ui/core/FormHelperText'
import { useField } from 'formik'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

const FormInputSelect = (props) => {
  const [field, meta] = useField(props);
  const hasError = Boolean(meta.touched && meta.error)

  const selects = props.options.map((v, i) => {
    return (
      <MenuItem key={i} value={v.value}>{v.label}</MenuItem>
    );
  });

  return (
    <Grid key={props.legend} item xs={12} style={{marginTop: 5}}>
    <Container align="left">
      <FormControl fullWidth={true} error={hasError} >
        <InputLabel  id={props.id}>{props.legend}</InputLabel>
        <Select
          style={{paddingTop:"1rem"}}
          labelId="demo-simple-select-label"
          id={props.id}
          {...field}>
          {selects}
        </Select>
        {hasError && <FormHelperText>Please complete this question</FormHelperText>}
     </FormControl>
    </Container>
    <Divider style={{ marginTop: 10 }}/>
    </Grid>
  );
};

export default FormInputSelect;