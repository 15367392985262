import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const WelcomeText = (props) => {

    const formattedWelcome = props.welcome.map((v, i) => {
        if (v.startsWith('**'))
        return (
          <Typography key={`Welcome part ${i}`}
          style={{
              margineTop:10,
              marginBottom: 10, 
              marginLeft:16, 
              marginRight:16,
              fontWeight: 'bold',
              textAlign: 'left'}} >{v.slice(2)}
              </Typography>
        );
        else return (
          <Typography key={`Welcome part ${i}`}
          style={{
              margineTop:10,
              marginBottom: 10, 
              marginLeft:16, 
              marginRight:16,
              fontWeight: 'fontWeightBold',
              textAlign: 'left'}} >{v}
              </Typography>
        );
      });

    return(
    
    <Container maxWidth="md">
        <Typography style={{margineTop:10, marginBottom: 10, marginLeft:16, marginRight:16, align:'left', textAlign: 'left'}}>{formattedWelcome}</Typography>
    </Container>
    )
}

export default WelcomeText;