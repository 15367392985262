import React, {useEffect, useState } from 'react';
import * as Yup from "yup"
import {Auth} from 'aws-amplify';
import Container from '@material-ui/core/Container';
import { useFormik } from 'formik'
import FormikForm from './FormikForm'
import FormButton from './FormButton'
import Grid from '@material-ui/core/Grid';
import TextInput from './FormInputText';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from './BackDrop'

const CognitoSignin = (props) => {
  const [noConnection, setNoConnection] = useState(false);
  const [invalidLogin, setInvalidLogin] = useState(null);
  

  function ValidationSchema () {
    var x = {}
    x.username = Yup.string().required()
    x.password = Yup.string().required()
    return Yup.object().shape(x)
}

function setInitalValues(){
  var x = {}
  x.username = ''
  x.password = ''
  return x
}


async function signIn(username, password) {
  try {
    await Auth.signIn(username.toLowerCase(), password);
    setInvalidLogin(false)
    props.handleSignIn()
    formikLogin.setSubmitting(false)

  } catch (err) {
    if (err.code === "UserNotConfirmedException") {
      
      setInvalidLogin(true)
      formikLogin.setSubmitting(false)
     
    } else if (err.code === "NotAuthorizedException") {
      // The error happens when the incorrect password is provided
      setInvalidLogin(true)
      formikLogin.setSubmitting(false)

    } else if (err.code === "UserNotFoundException") {
      // The error happens when the supplied username/email does not exist in the Cognito user pool
      setInvalidLogin(true)
      formikLogin.setSubmitting(false)

    } else {console.error(err)}
  }
}

  useEffect(() => {
    signIn(props.idu, props.idp)

  }, [props.idu, props.idp]) 

  const formikLogin = useFormik({
    initialValues:setInitalValues(),
    validationSchema:ValidationSchema(),

    onSubmit: values => {
        signIn(values.username, values.password)       
    },
  });

  return (

    <Container key={"form20"} maxWidth="xs">
     <FormikForm formik={formikLogin}>

   {!props.idu ?    <Grid container spacing={3}>
        
         {invalidLogin&& 
         <Grid item xs={12} sm={12}>
            <Alert severity="error">Username and or password invalid</Alert>
          </Grid>
          }
         {invalidLogin === false&& 
         <Grid item xs={12} sm={12}>
            <Alert severity="success">Login successful</Alert>
          </Grid>
          }

          <TextInput
            key={'username'}
            id={'username'}
            name={'username'}
            label={'username'}
            width={12}/>

          <TextInput
            key={'password'}
            id={'password'}
            name={'password'}
            label={'password'}
            type={'password'}
            width={12}/>
            
          <FormButton text={"Log in"} loading={formikLogin.isSubmitting} />

          {noConnection&&<Paper variant="outlined" elevation={3}>Connection can not be established please try again</Paper>}
       
        </Grid>:    <Backdrop visible={true}/> }
      </FormikForm>
    </Container>
  );
}

export default CognitoSignin;