import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import CardMedia from '@material-ui/core/CardMedia';
function FormLogo(props) {

  const [source, setSource] = useState(null);

  useEffect(() => {
    if(props.srcType === 'buffer'){
      let b = Buffer.from(props.src);
      let b64 = b.toString('base64')
      setSource("data:image/jpg;base64," + b64)
    }else {setSource(props.src)}

  }, [source,setSource, props.src, props.srcType])

  return (
    <Container key={"form40"} maxWidth="md">
      <CardMedia
        component="img"
        src={source}
        title="Company Logo"
      />
    </Container>
  );
}

export default FormLogo;
