import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const TermsAndConditions = (props) => {

    const formattedTerms = props.text.map((v, i) => {

        return (
          <Typography key={`T&C part ${i}`}
          style={{
              margineTop:10,
              marginBottom: 10, 
              marginLeft:16, 
              marginRight:16,
              textAlign: 'left'}} >{v}
              </Typography>
        );
      });

    return (
        <div>
            <br/>
            {props.termsView === false ? null : <Paper style={{margineTop:30, marginBottom: 30, textAlign: 'left'}} elevation={3}>{formattedTerms}</Paper> }
        </div>
    )
}

export default TermsAndConditions