import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, CircularProgress } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    }
}));
function LoadingBackground(props) {
    const classes = useStyles();
    let visible = props.visible
    return (
        <Backdrop className={classes.backdrop} open={visible}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}
export default LoadingBackground;