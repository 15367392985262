import React, { useState } from 'react';
import TermsAndConditions from '../components/TermsAndConditions';
import WelcomeText from '../components/WelcomeText';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

const Welcome = (props) => {
    const [termsView, setTermsView] = useState(false);

    function handleClose(event) {
        if (event) {
            setTermsView(false)
        }
    }
    function handleOpen(event) {
        if (event) {
            setTermsView(true)
        }
    }

    return(
        <div>
         <WelcomeText welcome={props.welcome} />
        {termsView === false ? <Button variant="contained" color="primary" onClick={handleOpen} >View Terms and Conditions</Button> : <Button variant="contained" color="primary" onClick={handleClose} >Hide Terms and Conditions</Button> }
        <TermsAndConditions text={props.tsncs} termsView={termsView} />
        <Button style={{margineTop:400, marginBottom: 20}} variant="contained" color="primary" onClick={() => { props.acceptTerms(true) }} >I have read and Agree to the Terms and Conditions</Button>
        <Divider/>
        </div>
    )
}

export default Welcome;