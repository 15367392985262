import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {useField} from 'formik'
import {subYears, formatISO, isValid} from "date-fns";
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';

const DatePicker = (props) => {
    const [field, meta, helpers] = useField(props);  
    const hasError = Boolean(meta.touched && meta.error)
    const [selectedDate, setSelectedDate] = React.useState(formatISO(new Date(), { representation: 'date' }));

  const handleDateChange = (date) => {
    setSelectedDate(date);

    if(isValid(date)){
      helpers.setValue(formatISO(date, { representation: 'date' }))
    }else{
      helpers.setError(true)
    }
  };
  
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
       <Grid item xs={12} sm={6} style={{marginTop: 5}}>
        <KeyboardDatePicker
          margin="normal"
          id={props.id}
          format="dd/MM/yyyy"
          value={selectedDate}
          helperText={hasError ?"Please enter valid Date of Birth": "Date of Birth"}
          error={meta.error && meta.touched}
          onChange={(date) => {handleDateChange(date)}}
          onBlur= {()=> {helpers.setTouched(true)}}
          fullWidth={true}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          disableToolbar={true}
          openTo={"year"}
          disableFuture={true}
          maxDate={subYears(new Date(), 10)}
          autoOk={true}
        />
        </Grid>

    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
