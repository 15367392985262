import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify'
import config from './aws-exports'
import { Switch, BrowserRouter, Route } from 'react-router-dom';

Amplify.configure(config)

const Routes = () => (
  <BrowserRouter>
      <Switch>
          <Route path="/user/:idu/:idp" component={App} />
          <Route path="/" component={App} />
      </Switch>
  </BrowserRouter>
);

ReactDOM.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();