import React, {useState} from "react";
import Fab from "@material-ui/core/Fab";
import {useField} from 'formik'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';
import FormHelperText from '@material-ui/core/FormHelperText';
import HelpIcon from '@material-ui/icons/Help';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';

const ColorPicker = props => {
  const [field, meta, helpers] = useField(props);
  const hasError = Boolean(meta.touched && meta.error)
  const [helpBox, setHelpBox] = useState(false)

  const [colorData, setColorData] = React.useState([
    { label: "white", colHex: "#ebebeb" },
    { label: "black", colHex: "#000000" },
    { label: "darkgreen", colHex: "#808000" },
    { label: "gray", colHex: "#808080" },
    { label: "green", colHex: "#008000" },
    { label: "darkblue", colHex: "#1b089c" },
    { label: "lightgreen", colHex: "#14e014" },
    { label: "lightblue", colHex: "#42c0fb" },
    { label: "yellow", colHex: "#ffff00" },
    { label: "pink", colHex: "#ffc0cb" },
    { label: "brown", colHex: "#964b00" },
    { label: "purple", colHex: "#b330ff" },
    { label: "orange", colHex: "#ff9e1f" },
    { label: "red", colHex: "#e81b05" },
  ...props.extraColors ? [{ label: 'strongRed', colHex: '#c70039'}, { label: 'lightCyan', colHex: '#b1f9ff' } ] : [],
  ]);

  const colorHandleOnChange = event => {
    helpers.setTouched(true)
    helpers.setError(true)
    var colorName = event.target.name

    setColorData(colorData => colorData.filter(color => color.label !== colorName));

    if (Object.keys(colorData).length === 1){
    setColorData([])
   }
    helpers.setValue(field.value.concat([event.target.name]))
 }

 const ColorPick = colorData.map((data) => {
   if (props.show){
   return (
     <Fab
       name={data.label}
       key={data.label}
       value={data.label}
       color="inherit"
       style={{
         backgroundColor: data.colHex,
         margin: 5,
       }}
       onClick={colorHandleOnChange}
     > </Fab>
   );}else{
     return null;
   }
 });

 return (
   
  <Grid key={props.legend} item xs={12} style={{margineTop:30, marginBottom: 30, textAlign: 'left'}}>
    {props.windowSmall && colorData.length ? <div><Typography color={hasError ? "error" :"inherit"}>Please <b>Rotate</b> <ScreenRotationIcon /> your screen to complete</Typography></div>
    :colorData.length ?<div>
      
    <Typography color="inherit"
    style={{
      padding: '10,0,100,1000', textAlign: 'left'
    }} variant="h6">Select the colour you are most drawn to until no colors remain <IconButton aria-label="help"
    onClick={() => {if(helpBox){setHelpBox(false)}else {setHelpBox(true)}}}>
    {!helpBox? <HelpOutlineOutlinedIcon fontSize="small" /> :<HelpIcon fontSize="medium" /> }
    </IconButton>
    </Typography>
  
    {helpBox && <Paper variant="outlined" elevation={3}> The colour you choose will disappear and you will need to choose the next colour you are most drawn to from the remaining colours. Continue to do this until the colours are ALL GONE. When there are no more colours simply click on the SUBMIT button.
    </Paper>}
    <div style={{ width: 540 }}> 
      {ColorPick}
    </div> 
    {hasError ? <FormHelperText error={true}>{'Please select all the colours until none remain'}</FormHelperText> :null}

    </div>:null
    }
  </Grid>
 );
};

export default ColorPicker;
