import React, { useCallback, useState, useEffect, useLayoutEffect } from 'react';
import './App.css';
import Amplify, { API, Auth} from 'aws-amplify';
import awsconfig from './aws-exports'
import AssessmentForm from './components/AssessementForm'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import FormLogo from './components/FormLogo';
import Welcome from './pages/Welcome';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import FormNotAvailable from './components/FormNotAvailable';
import CognitoSignin from './components/CognitoSignIn';
import DefaultLogo from '../src/images/cernova.png'

Amplify.configure(awsconfig)


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 10
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


function App(props) {
  const idu = props.match.params.idu
  const idp = props.match.params.idp
  const classes = useStyles();
  const [config, setConfig] = useState(null);
  const [user, setUser] = useState(null);
  const [termsAccept, setTermsAccept] = useState(null);
  const windowSmall = useWindowWidth()<550;
  const apiName = 'formapi';
  const path = '/form/config';

  //TODO refactor color function so it does not error if no color is found
  

  async function signOut() {
    try {
      await Auth.signOut();
      setTermsAccept(false)
      setConfig(null)
      setUser(false)
      if(config.config.form.signOutUrl){
        window.open(config.config.form.signOutUrl,"_self")
      }
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  if(config){
    var theme = createMuiTheme({
    palette: {
      primary: {
        main: config.config.form.colorPallet.primary,
      },
      secondary: {
        main:  config.config.form.colorPallet.secondary,
      },
      error: {
        main: config.config.form.colorPallet.error,
      },
    },
  });
  } else {
  var theme = createMuiTheme({
    palette: {
      primary: {
        main: '#142637',
      },
      secondary: {
        main:  '#99ccff',
      },
      error: {
        main:  '#e33371',
      }
      ,
    },
  });
  }
  function acceptTerms(accept) {
    if(accept){
      setTermsAccept(true)
    }
  }

  function useWindowWidth() {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      window.addEventListener('orientationchange', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const getAuthentication = useCallback(() => {
    Auth.currentSession().then(result => {
      setUser(result);
    })
      .catch(err => {
        console.log(err);
      })
  }, []);
 

  useEffect(() => {
    getAuthentication()
  }, [getAuthentication]);

  const handleSignIn =()=>{
    getAuthentication()
  }

  useEffect(() => {
    if (user){
    API.get(apiName, path).then(response => {
       setConfig(response.body) 
        if(response.body.config.form.bypassTC){
          setTermsAccept(true)
       }
    }).catch(error => {
      console.log(error.response)
    })
    .catch(err => console.log(err));
    }

  }, [apiName, path, user])

  function Header(){

    function logo(){ 
      if (config){
        if(!config.config.form.bypassLogo){
          if(config.image){
            return (<FormLogo srcType='buffer' src={config.image.data}/>)
          }
          if (config.config.form.bypassDefaultLogo) {
            return null
          }
          return(<FormLogo src={DefaultLogo}/>)      
        }
        return null
      } 
    }

    return(
    <header className="App-header" style={{ display: "flex", marginBottom:20}}>
      {logo()}
    </header>
    )

  }
  return (
    <MuiThemeProvider theme={theme}>

  
      <div className="App">
        <div className={classes.root}>
          <AppBar position="static" style={{background: 'opacity:0.5', boxShadow: 'none'}}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
              </Typography>
              {config && !config.config.form.logoutBypass ? <Button variant="contained" color="secondary" style={{ display: "flex-end", justifyContent: "right" }} onClick={() => signOut()} > Log Out </Button> : null}
            </Toolbar>
          </AppBar>
        </div>
        {Header()}

                {(!user || !config) && <CognitoSignin idu={idu} idp={idp} handleSignIn={handleSignIn}/>}

        
        {!termsAccept && config &&
         <Welcome acceptTerms={acceptTerms} 
        welcome={config.config.form.welcomeText} tsncs={config.config.form.tAndC} />}

        {(termsAccept && config) &&<AssessmentForm config={config.config} windowSmall={windowSmall} />}
        <h1></h1>
      </div>
    </MuiThemeProvider>

  );
}

export default App
