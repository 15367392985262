import React from 'react'; 
import { TextField } from "@material-ui/core";
import {useField} from 'formik'
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';

const FormInputText = (props) => {
  const [field, meta] = useField(props);
  const hasError = Boolean(meta.touched && meta.error)

  return (
    
      <Grid item xs={12} sm={props.width} style={{ marginTop: 5 }}>
        <TextField
        name={props.name}
        label={props.label}
        variant={props.variant}
        fullWidth={true}
        {...field}
        error={hasError}
        type={!props.type ? 'text' :props.type}
        />
        {meta.touched && meta.error ? <FormHelperText error={true}>{'Please enter valid '+props.label}</FormHelperText> :null}
    </Grid>
  );
};

export default FormInputText;
